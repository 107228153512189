<template>
<div style="height:100%;">
    <div class="nav_bar">
        <van-nav-bar
            title="信息确认"
            left-text="返回"
            left-arrow
            @click-left="$router.go(-1)"
            />
    </div>
<div class="app_question_div">
    <div style="height:46px;"></div>
    <div class="q_div">
        <div style="font-weight:bold;">是否需要过磅？</div>
        <div class="question_title">
            <van-radio-group>
                <van-cell-group>
                    <van-cell  clickable >
                        <template #right-icon>
                            <van-button :class="{btn_active:isWeight}" @click="isWeight=true;" block>需要过磅</van-button>
                        </template>
                    </van-cell>
                    <van-cell  clickable >
                        <template #right-icon>
                            <van-button :class="{btn_active:!isWeight}" @click="isWeight=false;" block>不需要过磅</van-button>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
        </div>
    </div>
    <QuestionTemplate :questionList='questionList' panelWidth='100%' :hideConfirm='true' @subCustomerPrice='confirmQuestion' @confirm='confirmQuestion'></QuestionTemplate>
    <div class="q_div" v-show="submit_show">
        <label>当前车辆整备质量如下（kg），将使用此质量生成最终报价！</label>
        <van-field v-model="autoWeight" type="number" readonly placeholder="请输入整备质量" />        
    </div>    
    <div class="price_div" v-show="submit_show">
        <div v-if="isWeight" style="padding:5px 5px;background-color:white;margin-top:10px;">
            <h4>车辆过磅后由客服联系客户进行报价！</h4>
        </div>
        <!-- <div v-else>
            <label>成交价格为（元）：</label>
            <van-field v-model="makerPrice" type="number" readonly placeholder="请输入最终价格" />
        </div> -->
        <!-- <label>用户预约价格为（元）：</label> -->
        <!-- <van-field v-model="customerPrice" type="number" :readonly='true' /> -->
        <van-button type="primary" @click="cq_question_show=!cq_question_show" block style="margin-top:10px;font-size:18px;">查看用户问卷</van-button>
        <div v-if="isWeight">
            <van-button type="primary" :loading="submit_loading" block style="margin-top:10px;font-size:18px;" @click="setWeight">确认订单</van-button>
        </div>
        <div v-else>
            <van-button type="primary" v-if="agentUser.authPay===0" :loading="submit_loading" block style="margin-top:10px;font-size:18px;"  @click="sendSMS">提交打款申请</van-button>
            <van-button type="primary" v-if="agentUser.authPay===1" :loading="submit_loading" block style="margin-top:10px;font-size:18px;" @click="sendSMS">确认订单</van-button>
        </div>
    </div>
    <van-dialog v-model="sms_show" title="请填写验证码" show-cancel-button :before-close="confirmOrder">
        <div>
            <label style="font-size:14px;margin:5px;text-indent: 2em;">{{"验证码已发送给号码为"+ dialog_title+"的手机，请联系获取验证码"}}</label>
            <van-field
                v-model="code"
                center
                clearable
                label="短信验证码"
                placeholder="请输入短信验证码"
                >
                <template #button>
                    <van-button size="small" @click.stop="sendSMS" :disabled='sms_setTimeout!==undefined' type="primary">{{sms_btn_text}}</van-button>
                </template>
            </van-field>
        </div>
    </van-dialog>
    <van-dialog v-model="cq_question_show"  show-cancel-button>
        <div style="height:60vh;padding:10px;overflow: scroll;">
            <div v-for="(item,index) in cs_questions" :key="index" >
                <van-cell-group>
                    <div>{{(index+1)+'、'}} {{item.label}}</div>
                    <div>
                        <van-cell >{{item.value[0].label}}</van-cell>
                    </div>
                </van-cell-group>
            </div>
        </div>
    </van-dialog> 
    <div style="height:40vh;" id="toscroll"><a href="#toscroll" id="a_to"></a></div>
</div>
<div class="gujia_div" v-show="submit_show">
    <van-button  type="primary" style="margin:10px 0px;font-size:20px;" color="#697d74" round block @click="subCustomerPrice()" >再次估价</van-button>
</div>
</div>
</template>
<script>
import {Button,Cell,CellGroup,Field,RadioGroup,Radio,Dialog,Icon,CheckboxGroup,Checkbox,NavBar} from 'vant';
import QuestionTemplate from '../../components/questionTemplate.vue'
import {GetQuestionList} from '../../api/common'
import {setWeight,SendScrapSMS,confirmOrder,getMakerPrice,getCQeustion } from '../../api/pricing'
import {pricingUser} from '../../store/pricing'
export default {
    components:{
        [Button.name]: Button,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        [Field.name]: Field,
        [Icon.name]: Icon,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Dialog.Component.name]: Dialog.Component,
        [NavBar.name]: NavBar,
        QuestionTemplate
    },
   data () {
    return {
        questionList:'',
        isWeight:false,
        photo_show:true,
        submit_loading:false,
        cq_question_show:false,
        question_show:true,
        currentQuestionsArr:[],
        isInputShow:false,
        questions:[],
        cs_questions:[],
        question_index:0,
        message_show:false,
        submit_show:false,
        info_msg:[],
        formData:[],
        scrapType:'',
        agentId:'',
        autoWeight:'',
        mobile:'',
        customerPrice:'',
        makerPrice:'',
        basePrice:'',
        orderid:'',
        firstSubmit:true,
        smsStatus:false,    
        agentUser:'',
        sms_index:60,    
        sms_btn_text:'再次发送',
        sms_setTimeout:'',
        sms_show:false,
        code:'',
        dialog_title:'',
    }
  },
  methods:{
      setWeight(){
          var body = {
              collectionId:this.$route.query.id
          };
          setWeight(body).then(res=>{
              this.formData = [];
              if(res.status){
                this.$toast.success('提交成功！');
                if(this.$util.isIOS()){
                    window.location.href = window.origin+"/pricingPhotos?orderId="+this.orderid;
                }else{
                    this.$router.push({path:'/pricingPhotos',query:{orderId:this.orderid}});
                }
              }else{
                this.$toast.fail(res.message);
              }
          });
      },
      sendSMS(){
          if(parseFloat(this.makerPrice)<=0){
              this.$toast.fail("请填写成交价格");
              return;
          }
          if(this.makerPrice<this.basePrice){
              this.$toast.fail("成交价格不能低于预估价格！");
              return;
          }
          if(!this.orderid){
              this.$toast.fail("请获取当前订单");
              return;
          }
          this.submit_loading=true;
          SendScrapSMS({mobile:this.mobile}).then(()=>{
              this.submit_loading=false;
              this.$toast("短信已发送");
              this.smsStatus = true;
              this.sms_show = true;
          });
          this.sms_setTimeout = setInterval(()=>{
              this.sms_index--;
              this.sms_btn_text = this.sms_index+"s";
              if(this.sms_index<0){
                  window.clearInterval(this.sms_setTimeout);
                  this.sms_setTimeout = undefined;
                  this.sms_btn_text='再次发送';
                  this.smsStatus=false;
                  this.sms_index = 60;
              }
          },1000)
      },
      confirmOrder(action, done){
        if (action === 'confirm') {
            var body={
                "orderId": this.orderid,
                "additionalAmount": this.makerPrice-this.basePrice,
                "basePrice": this.basePrice,
                "code": this.code
            };
            this.$toast.loading({duration: 0,forbidClick: true,message: '提交中',});
            confirmOrder(body).then(res=>{
                this.$toast.clear();
                done();
                if(res.status){
                    this.$toast.success('提交成功！请等待管理员通过打款申请');
                    if(this.$util.isIOS()){
                        window.location.href = window.origin+"/pricingPhotos?orderId="+this.orderid;
                    }else{
                        this.$router.push({path:'/pricingPhotos',query:{orderId:this.orderid}});
                    }
                }else{
                    this.$toast.fail(res.message);
                }
            });
        }else{
            done();
        }
      },
      confirmQuestion(formData){
          console.log(formData);
          this.formData = formData;
          this.submit_show = true;
          this.subCustomerPrice();
      },
      subCustomerPrice(){
          if(!this.autoWeight)
          {
              this.$toast('装备重量不能为空');
              return;
          }
          this.submit();
      },
      toggle(index) {
            this.$refs.checkboxes[index].toggle();
        },
      submit(){
          this.question_show = false;
          this.message_show = true;
          var body = {
            autoWeight: this.autoWeight,
            scrapType: this.scrapType,
            questions:this.formData,
            orderId:this.orderid,
            agentId:this.agentId,
            province:this.$route.query.province,
            city:this.$route.query.city
          };
          this.$toast.loading({duration: 0,forbidClick: true,message: '估价中···',});
          getMakerPrice(body).then(res=>{
              this.$toast.clear();
              if(res.status){
                this.$toast.success(res.message);
                this.makerPrice = res.data;
                this.firstSubmit = false;
                this.basePrice = res.data;
              }else{
                  this.$toast.fail(res.message);
              }
          });
      },         
  },
  mounted(){
      this.autoWeight = this.$route.query.autoWeight;
      this.scrapType = this.$route.query.scrapType;
      this.orderid = this.$route.query.orderId;
      this.customerPrice = this.$route.query.customerPrice;
      this.agentId = this.$route.query.agentId;
      this.mobile =this.$route.query.mobile;
      this.dialog_title =this.mobile.substring(0,3)+"****"+this.mobile.substring(7);
      this.agentUser = pricingUser.get();
      this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
      getCQeustion({id:this.orderid}).then(res=>{
          this.$toast.clear();
          this.cs_questions= res;
      }).catch((err) => {
          this.$toast.clear();
          console.log(err);
      });
  },
  created(){
    GetQuestionList({province:this.$route.query.province,city:this.$route.query.city,licensePrefix:this.$route.query.license.substring(0,2),agentId:this.$route.query.agentId})
    .then(res=>{
        if(res.length>0){         
            this.questionList = res;
            console.log(this.questionList);
        }else{
            this.$dialog.alert({
                title: '提示',
                message: '当前地区未配置问卷，请联系拆解厂管理人员！',
                theme: 'round-button',
            }).then(() => {
                this.$router.go(-1);
            });
        }
    })
  }
}
</script>
<style scoped>
.child_ques{
    padding-left: 10px;
}
.nav_bar{
    position: fixed;
    width: 100%;
    z-index: 2;
}
.gujia_div{
    /* padding: 0px 10px; */
    background-color: white;
    position: fixed;
    bottom: 0px;
    width: 100vw;
}
.app_question_div{
    /* width:100%; */
    padding:10px;
    background-color:#f0f0f0;
    height: 100%;
    font-family: 'Microsoft YaHei' !important;
    font-size: 18px;
    overflow:auto;
}
.q_div{
    margin-top: 10px;
    background-color:white;
    padding: 10px;
}
.btn_active{
    background-color: #feffeb;
    border:1px solid #ffdba5;
}
</style>